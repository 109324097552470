/* General */
:root {
  --ms-primary-color: #ff374c;
  --ms-primary-color-darker: #ce2537;
  --ms-primary-color-darkest: #a2101f;
  --ms-secondary-color: #1c2133;
}

* {
  font-family: 'Poppins', sans-serif;
}

.shadowed-element {
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
}

.btn {
  border-radius: 30px;
}

.btn-primary {
  background: var(--ms-primary-color);
  border-color: var(--ms-primary-color);
}

.btn.btn-primary:hover, .btn-outline-primary:hover, .btn.btn-outline-primary:active {
  background-color: var(--ms-primary-color-darker);
  border-color: var(--ms-primary-color-darker);
}

.btn-outline-primary {
  border-color: var(--ms-primary-color);
  color: var(--ms-primary-color);
}

.btn-check.btn-primary:checked+.btn,
.btn.btn-primary.active,
.btn.btn-primary.show,
.btn.btn-primary:first-child:active,
:not(.btn-check)+.btn.btn-primary:active,
.btn.btn-primary:disabled {
  background-color: var(--ms-primary-color-darkest);
  border-color: var(--ms-primary-color-darkest);
}

.btn-link {
  color: var(--ms-primary-color);
  text-decoration: none;
}

.card {
  border-radius: 30px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.form-control, .form-select {
  border-radius: 30px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
  border-color: white;
  padding: 1rem 1.5rem;
}

.form-control:focus, .form-select:focus {
  box-shadow: 0 5px 10px rgb(13 110 253 / 25%);
}

.form-control.is-invalid:focus, .form-select.is-invalid:focus {
  box-shadow: 0 5px 10px rgb(220 53 69 / 25%);
}

.input-group:has(.is-invalid)~.invalid-feedback {
  display: block;
}

.form-floating>.form-control, .form-floating>.form-control-plaintext {
  padding: 1rem 1.5rem;
}

.form-floating>label {
  padding: 1rem 1.5rem;
  opacity: .65;
}

.input-group {
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
  border-radius: 30px;
}

.input-group:has(.form-control:focus) {
  box-shadow: 0 5px 10px rgb(13 110 253 / 25%);
}

.input-group:has(.form-control.is-invalid:focus) {
  box-shadow: 0 5px 10px rgb(220 53 69 / 25%);
}

.input-group .form-control {
  box-shadow: none;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-radius: 30px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: white;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control, .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select, .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-right: 0;
}

.input-group:has(.form-control:focus)>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-color: #86b7fe;
}

.input-group:has(.form-control.is-invalid)>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-color: #dc3545;
}

.input-group .form-control.is-invalid:focus, .input-group .was-validated .form-control:invalid:focus {
  box-shadow: none;
}


.form-check-input[type=checkbox] {
  border-radius: 50%;
  border-color: var(--ms-primary-color);
}

.form-check-input:checked[type=checkbox] {
  background-color: var(--ms-primary-color);
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(255 55 76 / 25%);
}

.alert {
  border-radius: 30px;
}

ul.nav[role=tablist] li:nth-child(2n+1) {
  border-right: 1px solid var(--bs-gray);
}

ul.nav[role=tablist] li:last-of-type {
  border-right: 0;
}

ul.nav[role=tablist] li button {
  width: 100%;
  color: #9f9f9f;
  font-size: 21px;
}

ul.nav[role=tablist] li button.active {
  color: var(--dark);
  position: relative;
}

ul.nav[role=tablist] li button span {
  position: relative;
}

ul.nav[role=tablist] li button.active span:after,
ul.nav[role=tablist] li button:hover span:after {
  -webkit-animation: animateWidthFull 0.2s forwards;
  animation: animateWidthFull 0.2s forwards;
}
ul.nav[role=tablist] li button span:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  background: var(--ms-primary-color);

  -webkit-animation: animateWidthZero 0.2s forwards;
  animation: animateWidthZero 0.2s forwards;
}

p.attention {
  text-align: center;
  font-size: 29px;
  font-weight: bold;
  line-height: 1.3em;
}

.ms-badge {
  display: inline-block;
  padding: 10px 20px;
  margin-right: 20px;
  border-radius: 30px;
  margin-bottom:10px;
}

@media (max-width:767px) {
  ul.nav[role=tablist] li {
    position: relative;
    border: 0!important;
  }
  ul.nav[role=tablist] li:not(:last-of-type):after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -4px;
    transform: translateX(-50%);
    width: 80%;
    height: 1px;
    background: var(--bs-gray);
    opacity:.3;
  }

  p.attention {
    font-size:23px;
  }
}

/* App */
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Header */
#header {
  background: #1c2133;
  position: relative;
  padding: 15px 0;
}

img.header-logo {
  height: 50px;
}

.header-divider-container {
  position: absolute;
  bottom: -20px;
  z-index: 9;
  width: 100%;
  height: 20px;
}

.header-divider-container img {
  height: 100%;
  width: 100%;
  display: block;
}

img.user-image {
  max-width: 50px;
  height: auto;
  border-radius: 50%;
}

div#header-user-info {
  margin-left: auto;
  display: flex;
  align-items: center;
  color: white;
}

p.user-name {
  margin-bottom: 0;
  margin-left: 15px;
  font-size: 13px;
}

p.user-name .main-name {
  font-size: 17px;
  position: relative;
  display: inline-block;
  margin-bottom: 3px;
}

p.user-name .main-name:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--ms-primary-color);
}

.dropdown-menu .dropdown-item {
    padding-top: 10px;
    padding-bottom: 10px;
}

.dropdown-menu {
    border-radius: 30px;
    text-align: center;
    padding: 0;
}

ul.dropdown-menu:before {
  display: block;
  box-sizing: content-box;
  position: absolute;
  border: 8px solid rgba(0,0,0,0);
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  border-bottom-color: white;
  top: -15px;
  right: 20px;
}

.dropdown-menu .dropdown-item:last-of-type {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.dropdown-menu .dropdown-item:first-of-type {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

@media (max-width: 500px) {
  img.header-logo {
    height: 25px;
  }
}

/* Content */
#content {
  flex-grow: 1;
  background-repeat: no-repeat;
  background-position: 100% 0;
}

/* Login Page */
#login-container {
  margin-top: 150px;
}

/* 
  EVENT PAGE
*/
#greeting {
  padding: 40px 15px 15px;
  background: var(--bs-gray-400);
}

.event-image-holder {
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid white;
  position: relative;
}

.event-image-holder .loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 39px;
  color: white;
  background: rgba(0, 0, 0, .3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-btn {
  background: var(--ms-primary-color);
  border: 10px solid white;
  border-radius: 50%;
  color: white;
  width: 150px;
  height: 150px;
  font-size: 17px;
  font-weight: bold;
  box-shadow: 0 -5px 15px rgb(0 0 0 / 30%);
  transition: .15s all;
  display: inline-flex;
  flex-direction: column;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}

.event-btn:hover {
  color:white;
}

.event-btn i {
  font-size: 29px;
}

.event-btn span {
  text-align: center;
}

.event-btn:active {
  box-shadow: 0 0px 5px rgb(0 0 0 / 30%);
  transform: scale(0.95);
}

a.navigation-button {
  display: block;
  background: white;
  margin-bottom: 20px;
  padding: 20px 15px;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
  color: var(--bs-dark);
  text-decoration: none;
}

a.navigation-button[disabled] {
  opacity: 0.3;
}

a.navigation-button strong {
  position: relative;
  font-size: 23px;
}

a.navigation-button > span {
  color: var(--bs-gray-500);
}

a.navigation-button.active:not([disabled]) strong:after,
a.navigation-button:hover:not([disabled]) strong:after {
  -webkit-animation: animateWidthFull 0.2s forwards;
  animation: animateWidthFull 0.2s forwards;
}
a.navigation-button:not([disabled]) strong:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  background: var(--ms-primary-color);

  -webkit-animation: animateWidthZero 0.2s forwards;
  animation: animateWidthZero 0.2s forwards;
}

ul#event-description-tabs li button {
  font-size: 17px;
  padding: 5px 0px;
}

/* Dashboard */
.progressbar-container {
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  padding: 10px;
  max-width: 250px;
  margin: 0 auto;
}

.progress {
  background: white;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  height: 25px;
  border-radius: 15px;
}

.progress-bar {
  background: var(--ms-primary-color);
}

img.event-target-icon {
  width: 80px;
}

.card.event-target-card {
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  min-width: 170px;
}

/* Join */
.card.selectable {
  cursor: pointer;
  transition: .15s all;
}

.card.selectable:not(.disabled):active {
  box-shadow: 0 0 0px rgb(0 0 0 / 20%);
  transform: scale(.98);
}

.card.selectable.selected {
  background: var(--ms-primary-color);
  color: white;
  border-color: var(--ms-primary-color);
}

.card.selectable.disabled {
  opacity: .3;
  cursor: auto;
}

@media (max-width: 767px) {
  .event-image-holder {
    max-width: 50%;
  }
}

/* Add activity */
.uploaded-image {
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
  border-radius: 30px;
  overflow: hidden;
}

/* Image Picker */

img.user-image-selection {
  max-width: 150px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  margin-right: 15px;
}

img.user-image-option {
  max-width: 100%;
  border-radius: 50%;
  cursor: pointer;
  transition: .15s all;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

img.user-image-option:active {
  transform: scale(0.95);
  box-shadow: 0 0 0px rgb(0 0 0 / 20%);
}

/* ANIMATIONS */
@-webkit-keyframes animateWidthFull {
	0% { width: 0; }
  100% { width: 100%; }
}

@keyframes animateWidthFull {
	0% { width: 0; }
  100% { width: 100%; }
}

@-webkit-keyframes animateWidthZero {
	0% { width: 100%; }
  100% { width: 0; }
}

@keyframes animateWidthZero {
	0% { width: 100%; }
  100% { width: 0; }
}

/**
  EVENT LIST ELEMENT
**/

.event-list-element {
  background-size: cover;
  border-radius: 30px;
  padding: 20px;
  padding-top: 80%;
  display: block;
  text-align: center;
  text-decoration: none;
  color: white;
  transition: all .15s;
}

.event-list-element:hover {
  transform: scale(1.05);
  color: white;
}